import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Arrow from "../../../Helpers/icons/Arrow";
import { ErrorToaster } from "../../../Helpers/toaster/Toaster";
import CategoryServices from "../../../../apis/category/CategoryServices";
import { Backdrop, Menu, Popover } from "@mui/material";

export default function Navbar({ className, type, userTypes }) {

  const navigate = useNavigate();
  let { pathname } = useLocation();

  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filterSubCategories, setFilterSubCategories] = useState([]);

  const [fullBaseUrl, setFullBaseUrl] = useState('');

  const [registerToggle, setRegisterToggle] = useState(false);

  const [categoryToggle, setToggle] = useState(false);

  const [elementsSize, setSize] = useState(0);

  const handler = () => {
    setToggle(!categoryToggle);
  };

  // *For Get Categories
  const getCategories = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 200
      }
      const { data, imageBaseUrl } = await CategoryServices.getCategories(params)
      setFullBaseUrl(imageBaseUrl)
      setCategories(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Get Sub Categories
  const getSubCategories = async () => {
    try {
      let params = {
        search: '',
        page: 1,
        limit: 200
      }
      const { data } = await CategoryServices.getSubcategories(params)
      setSubCategories(data)
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const handleSubCategory = (id) => {
    try {
      const isExist = categoriesId.includes(id)
      if (isExist) {
        setCategoriesId(categoriesId.filter(e => e !== id))
      } else {
        setCategoriesId([...categoriesId, id])
      }
    } catch (error) {
      console.log('handleSubCategory => error:', error)
    }
  }

  useEffect(() => {
    const getItems = categories.length;
    if (pathname === '/') {
      if (getItems > 0) {
        setSize(42 * getItems);
      }
    } else {
      if (categoryToggle) {
        if (categoryToggle && getItems > 0) {
          setSize(42 * getItems);
        }
      } else {
        setSize(0);
      }
    }
  }, [pathname, categoryToggle, categories]);

  useEffect(() => {
    getCategories()
    getSubCategories()
  }, []);

  return (
    <div
      className={`nav-widget-wrapper w-full  h-[60px] relative z-30 ${type === 3 ? "bg-qh3-blue" : "bg-qyellow"
        }  ${className || ""}`}
    >
      <div className="container-x mx-auto h-full">
        <div className="w-full h-full relative">
          <div className="w-full h-full flex justify-between items-center">
            <div className="category-and-nav flex xl:space-x-7 space-x-3 items-center">
              <div className="category w-[270px] h-[53px] bg-white px-5 rounded-t-md mt-[6px] relative">
                <button
                  onClick={handler}
                  type="button"
                  className="w-full h-full flex justify-between items-center"
                >
                  <div className="flex space-x-3 items-center">
                    <span>
                      <svg
                        className="fill-current"
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="14" height="1" />
                        <rect y="8" width="14" height="1" />
                        <rect y="4" width="10" height="1" />
                      </svg>
                    </span>
                    <span className="text-sm font-600 text-qblacktext">
                      All Categories
                    </span>
                  </div>
                  <div>
                    {elementsSize == 0 ? (
                      <span>
                        <svg
                          className="fill-current"
                          width="6"
                          height="9"
                          viewBox="0 0 6 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="1.49805"
                            y="0.818359"
                            width="5.78538"
                            height="1.28564"
                            transform="rotate(45 1.49805 0.818359)"
                          />
                          <rect
                            x="5.58984"
                            y="4.90918"
                            width="5.78538"
                            height="1.28564"
                            transform="rotate(135 5.58984 4.90918)"
                          />
                        </svg>
                      </span>
                    ) : (
                      <Arrow
                        width="5.78538"
                        height="1.28564"
                        className="fill-current text-qblacktext"
                      />
                    )}
                  </div>
                </button>
                {categoryToggle && (
                  <div
                    className="fixed top-0 left-0 w-full h-full -z-10"
                    onClick={handler}
                  ></div>
                )}
                <div
                  className="category-dropdown w-full absolute left-0 top-[53px] overflow-auto"
                  style={{ height: `${elementsSize}px` }}
                >
                  <ul className="categories-list">
                    {categories?.map((item, index) => (
                      <li key={index} className="category-item">
                        {/* <Link to={`/all-products?categories=${item?.id}`}> */}
                        <div
                          onClick={(e) => handleSubCategory(item.id)}
                          className={`flex justify-between items-center px-5 h-10 bg-white  transition-all duration-300 ease-in-out cursor-pointer text-qblack ${type === 3
                            ? "hover:bg-qh3-blue hover:text-white"
                            : "hover:bg-qyellow hover:text-white"
                            }`}
                        >
                          <div className="flex items-center space-x-6">
                            {/* <span>
                                <img className="category-image" src={fullBaseUrl + item?.image} alt="icon" />
                              </span> */}
                            <span className="text-xs font-400">
                              {item?.name}
                            </span>
                          </div>
                          <div>
                            {categoriesId.includes(item.id) ? (
                              <Arrow
                                width="5.78538"
                                height="1.28564"
                                className="fill-current text-qblacktext"
                              />
                            ) : (
                              <span>
                                <svg
                                  className="fill-current"
                                  width="6"
                                  height="9"
                                  viewBox="0 0 6 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="1.49805"
                                    y="0.818359"
                                    width="5.78538"
                                    height="1.28564"
                                    transform="rotate(45 1.49805 0.818359)"
                                  />
                                  <rect
                                    x="5.58984"
                                    y="4.90918"
                                    width="5.78538"
                                    height="1.28564"
                                    transform="rotate(135 5.58984 4.90918)"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        </div>
                        {/* </Link> */}
                        {categoriesId.includes(item.id) &&
                          <ul className="categories-list">
                            {subCategories.filter(e => e.head_id === item.id)?.map((item, index) => (
                              <li key={index} className="category-item">
                                <Link to={`/all-products?categories=${item?.head_id}&subcategories=${item?.id}`}>
                                  <div
                                    className={`flex justify-between items-center px-5 pl-10 h-10 bg-qgray-border transition-all duration-300 ease-in-out cursor-pointer text-qblack ${type === 3
                                      ? "hover:bg-qh3-blue hover:text-white"
                                      : "hover:bg-qyellow hover:text-white"
                                      }`}
                                  >
                                    <div className="flex items-center space-x-6">
                                      {/* <span>
                                <img className="category-image" src={fullBaseUrl + item?.image} alt="icon" />
                              </span> */}
                                      <span className="text-xs font-400">
                                        {item?.name}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="nav">
                <ul className="nav-wrapper flex xl:space-x-10 space-x-5">
                  <li className="relative">
                    <Link to="/">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>Home</span>
                      </span>
                    </Link>
                  </li>
                  <li style={{ display: 'none' }}>
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                        }`}
                    >
                      <span>Shop</span>
                      <span className="ml-1.5 ">
                        <Arrow className="fill-current" />
                      </span>
                    </span>
                    <div className="sub-menu w-full absolute left-0 top-[60px]">
                      <div
                        className="mega-menu-wrapper w-full bg-white p-[30px] flex justify-between items-center "
                        style={{
                          minHeight: "295px",
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        <div className="categories-wrapper flex-1 h-full flex justify-around -ml-[70px]">
                          <div>
                            <div className="category">
                              <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">
                                Shop List
                              </h1>
                            </div>
                            <div className="category-items">
                              <ul className="flex flex-col space-y-2">
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop Sidebar
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop Fullwidth
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop Category Icon
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop Category Icon
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop List View
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div>
                            <div className="category">
                              <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">
                                Product Layouts
                              </h1>
                            </div>
                            <div className="category-items">
                              <ul className="flex flex-col space-y-2">
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Horizonral Thumbnail
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Vertical Thumbnail
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Gallery Thumbnail
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Sticky Summary
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div>
                            <div className="category">
                              <h1 className="text-[13px] font-700 text-qblack uppercase mb-[13px]">
                                Polular Category
                              </h1>
                            </div>
                            <div className="category-items">
                              <ul className="flex flex-col space-y-2">
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Phone & Tablet
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Gaming & Sports
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Home Appliance
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Fashion Clothes
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="thumbnil w-[348px] h-full">
                          <div className="w-full h-[235px]">
                            <img
                              width=""
                              src={`${process.env.PUBLIC_URL}/assets/images/mega-menu-thumb.jpg`}
                              alt=""
                              className="w-full h-full object-contain"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li className="relative">
                    <span
                      className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                        }`}
                    >
                      <span>Pages</span>
                      <span className="ml-1.5 ">
                        <Arrow className="fill-current" />
                      </span>
                    </span>
                    <div className="sub-menu w-[220px] absolute left-0 top-[60px]">
                      <div
                        className="w-full bg-white flex justify-between items-center "
                        style={{
                          boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                        }}
                      >
                        <div className="categories-wrapper w-full h-full p-5">
                          <div>
                            <div className="category-items">
                              <ul className="flex flex-col space-y-2">
                                <li>
                                  <a href="/privacy-policy">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Privacy Policy
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/terms-condition">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Terms and Conditions
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/faq">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      FAQ
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop Category Icon
                                    </span>
                                  </a>
                                </li>
                                <li>
                                  <a href="/all-products">
                                    <span
                                      className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                        ? "hover:text-qh3-blue hover:border-qh3-blue"
                                        : "hover:text-qyellow hover:border-qyellow"
                                        }`}
                                    >
                                      Shop List View
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    <Link to="/about">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>About</span>
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/blogs">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>Blog</span>
                      </span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>Contact</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>FAQ</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <a href="/#new-arrivals">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>New Arrivals</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#top-seller">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>Top Seller</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/#top-selling">
                      <span
                        className={`flex items-center text-sm font-600 cursor-pointer ${type === 3 ? "text-qblacktext" : "text-white"
                          }`}
                      >
                        <span>Top Selling</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="become-seller-btn relative">
              <div onClick={() => { navigate('/guest-register', { state: { userType: userTypes.find(e => e.label === 'Guest Buyer')?.value } }); setRegisterToggle(false) }} className="black-btn w-[161px] h-[40px] flex justify-center items-center cursor-pointer">
                <div className="flex space-x-2 items-center">
                  <span className="text-sm font-600">Register Now</span>
                  <span>
                    <svg
                      className="fill-current"
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="1.08984"
                        width="6.94106"
                        height="1.54246"
                        transform="rotate(45 1.08984 0)"
                        fill="white"
                      />
                      <rect
                        x="6"
                        y="4.9082"
                        width="6.94106"
                        height="1.54246"
                        transform="rotate(135 6 4.9082)"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {registerToggle &&
                <div className="sub-menu w-[220px] absolute left-0 top-[50px]">
                  <div
                    className="w-full bg-white flex justify-between items-center "
                    style={{
                      boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.14)",
                    }}
                  >
                    <div className="categories-wrapper w-full h-full p-5">
                      <div>
                        <div className="category-items">
                          <ul className="flex flex-col space-y-2">
                            {userTypes.map((item, index) => (
                              <li key={index} className={'cursor-pointer'} onClick={() => { navigate('/guest-register', { state: { userType: item.value } }); setRegisterToggle(false) }}>
                                <span
                                  className={`text-qgray text-sm font-400 border-b border-transparent   ${type === 3
                                    ? "hover:text-qh3-blue hover:border-qh3-blue"
                                    : "hover:text-qyellow hover:border-qyellow"
                                    }`}
                                >
                                  {item.label}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
