import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import userDataReducer from './slices/userDataSlice'
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};
const reducers = combineReducers(
  {
    userDataReducer: userDataReducer,
  });
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore persist-related actions for serializability
      },
    }).concat(thunk),
});