import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart, removeToCart } from "../../redux/slices/userDataSlice";
import { ErrorToaster } from "./toaster/Toaster";

export default function InputQuantityCom({ data }) {

  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);

  const increment = () => {
    dispatch(addToCart({ ...data, quantity: 1, }))
    setQuantity((prev) => prev + 1);
  };

  const decrement = () => {
    if (data?.min_quantity === quantity) {
      ErrorToaster(`The minimum order quantity must be at least ${data?.min_quantity} items.`)
    } else {
      dispatch(removeToCart({ ...data, quantity: 1, }))
      setQuantity((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setQuantity(data.quantity);
  }, [data]);

  return (
    <div className="w-[120px] h-[40px] px-[26px] flex items-center border border-qgray-border">
      <div className="flex justify-between items-center w-full">
        <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray"
        >
          -
        </button>
        <span className="text-qblack">{quantity}</span>
        <button
          onClick={increment}
          type="button"
          className="text-base text-qgray"
        >
          +
        </button>
      </div>
    </div>
  );
}
