import { createSlice } from '@reduxjs/toolkit'
import CartServices from '../../apis/cart/CartServices';
import { ErrorToaster } from '../../components/Helpers/toaster/Toaster';

const initialState = {
  cart: [],
  totalAmount: 0
}

export const userDataSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCartState: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      const existingItem = state.cart.find(item => item.id === id);
      state.totalAmount += actualPrice * quantity
      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.cart.push({ ...action.payload });
      }
    },
    updateCart: (state, action) => {
      state.totalAmount = action?.payload?.totalAmount
      state.cart = action?.payload?.cart
    },
    removeToCartState: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      const existingItem = state.cart.find(item => item.id === id);
      state.totalAmount -= actualPrice * quantity
      if (existingItem.quantity > 1) {
        existingItem.quantity -= 1;
      } else {
        const index = state.cart.findIndex(item => item.id === id);
        if (index !== -1) {
          state.cart.splice(index, 1);
        }
      }
    },
    removeProductToCartState: (state, action) => {
      const { id, price, discounted_price, discount_percentage, flash_sale_price, quantity } = action.payload;
      let actualPrice = price
      if (flash_sale_price > 0) {
        actualPrice = flash_sale_price
      } else if (discounted_price > 0) {
        actualPrice = discounted_price
      } else if (discount_percentage > 0) {
        actualPrice = price - (discount_percentage / 100) * price
      }
      state.totalAmount -= actualPrice * quantity
      const index = state.cart.findIndex(item => item.id === id);
      if (index !== -1) {
        state.cart.splice(index, 1);
      }
    },
    clearCartState: (state, action) => {
      state.totalAmount = 0
      state.cart = []
    },
  },
});

// Thunks to handle async API calls and state synchronization
export const addToCart = (item) => async (dispatch, getState) => {
  dispatch(addToCartState(item));
  await callUpdateCartApi(dispatch, getState);
};

export const removeToCart = (item) => async (dispatch, getState) => {
  dispatch(removeToCartState(item));
  await callUpdateCartApi(dispatch, getState);
};

export const removeProductToCart = (item) => async (dispatch, getState) => {
  dispatch(removeProductToCartState(item));
  await callUpdateCartApi(dispatch, getState);
};

export const clearCart = () => async (dispatch, getState) => {
  dispatch(clearCartState());
  await callDeleteCartApi(dispatch, getState);
};

// Utility function to sync with API
const callUpdateCartApi = async (dispatch, getState) => {
  try {
    const { cart, totalAmount } = getState().userDataReducer;
    const cartData = {
      cart: cart,
      totalAmount: totalAmount,
    };
    await CartServices.setCartData({ cart: JSON.stringify(cartData) });
  } catch (error) {
    ErrorToaster(error);
  }
};

const callDeleteCartApi = async (dispatch, getState) => {
  try {
    await CartServices.deleteCartData()
  } catch (error) {
    ErrorToaster(error);
  }
};


// Action creators are generated for each case reducer function
export const { addToCartState, updateCart, removeToCartState, removeProductToCartState, clearCartState } = userDataSlice.actions

export default userDataSlice.reducer

