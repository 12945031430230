import { Link, useNavigate } from "react-router-dom";
import Cart from "../../../Cart";
import Compair from "../../../Helpers/icons/Compair";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import SearchBox from "../../../Helpers/SearchBox";
import { useAuth } from "../../../../context/UseContext";
import { Avatar, Box, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Dashboard, Logout } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { DropShipperTypeId, GuestBuyerTypeId, ManufacturerTypeId, SupplierTypeId } from "../../../../utils";
import { clearCart } from "../../../../redux/slices/userDataSlice";

function DropDown({ anchorEl, openDropdown, handleClose }) {

  const { userLogout, user } = useAuth();
  const dispatch = useDispatch();

  const userData = JSON.stringify(user);

  return (
    <Menu
      anchorEl={anchorEl}
      open={openDropdown}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '.MuiSvgIcon-root': {
            width: 20,
            height: 20,
            ml: 0.5,
            mr: 0.5,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      {(user?.type == ManufacturerTypeId || user?.type == SupplierTypeId) &&
        <MenuItem>
          <a href={'https://seller.hashtag.com.co/login?token=' + btoa(userData)} target="_blank" rel="noreferrer">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            Seller Panel
          </a>
        </MenuItem>
      }
      {(user?.type == DropShipperTypeId || user?.type == SupplierTypeId || user?.type == GuestBuyerTypeId) &&
        <MenuItem>
          <a href={'https://buyer.hashtag.com.co/login?token=' + btoa(userData)} target="_blank" rel="noreferrer">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            Buyer Panel
          </a>
        </MenuItem>
      }
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => { userLogout(); dispatch(clearCart()) }}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )
}

export default function Middlebar({ className, type }) {

  const { user } = useAuth();
  const { cart } = useSelector(state => state.userDataReducer)

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  return (
    <div className={`w-full h-[86px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              <Link to="/">
                <img
                  className="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/hashtag-logo.png`}
                  alt="hashtag"
                />
              </Link>
            </div>
            <div
              className="h-[44px]"
              style={{ flex: '0.8' }}
            >
              <SearchBox type={type} className="search-com" />
            </div>
            <div className="flex space-x-6 items-center">
              {/* <div className="compaire relative">
                <a href="/products-compaire">
                  <span>
                    <Compair />
                  </span>
                </a>
                <span className={`badge w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}>
                  2
                </span>
              </div> */}
              {/* <div className="favorite relative">
                <a href="/wishlist">
                  <span>
                    <ThinLove />
                  </span>
                </a>
                <span className={`badge w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}>
                  1
                </span>
              </div> */}
              <div className="cart-wrapper group relative py-4">
                <div className="cart relative cursor-pointer">
                  <Link to="/cart">
                    <span>
                      <ThinBag />
                    </span>
                  </Link>
                  <span className={`badge w-[18px] h-[18px] rounded-full  absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] ${type === 3 ? 'bg-qh3-blue text-white' : 'bg-qyellow'}`}>
                    {cart?.length}
                  </span>
                </div>
                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                {/* hidden group-hover:block" */}
                <Cart type={type} className="absolute -right-[45px] top-11 z-50 hidden group-hover:block" />
              </div>
              {user?.token ? (
                <Box sx={{ cursor: 'pointer' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <Avatar
                    src={""}
                    sx={{
                      width: 30,
                      height: 30,
                      border: `1px solid #234386`,
                      '.MuiAvatar-img': { objectFit: 'contain' }
                    }}
                  />
                </Box>
              ) : (
                <Link to="/login">
                  <div className="black-btn w-[120px] h-[40px] flex justify-center items-center cursor-pointer">
                    <div className="flex space-x-2 items-center">
                      <span className="text-sm font-600">Login</span>
                    </div>
                  </div>
                </Link>
              )}
              <DropDown anchorEl={anchorEl} openDropdown={openDropdown} handleClose={() => setAnchorEl(null)} />
              {/* <div>
                <button type="button">
                  <span>
                    <ThinPeople />
                  </span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
