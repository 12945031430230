import { useEffect, useRef, useState } from "react";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { ErrorToaster, SuccessToaster } from "../Helpers/toaster/Toaster";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DropShipperTypeId, emailRegex, ManufacturerTypeId, mobileRegex, passwordRegex, SupplierTypeId } from "../../utils";
import AuthServices from "../../apis/auth/AuthServices";
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import { Box, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function GuestBuyer() {

  const { state } = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const userName = state?.userType == ManufacturerTypeId ? "Manufacturer" : state?.userType == DropShipperTypeId ? "Dropshipper" : state?.userType == SupplierTypeId ? "Supplier" : "Guest Buyer"

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // *For Registration
  const registration = async (data) => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("name", data?.name)
      formData.append("username", data?.username)
      formData.append("contact", data?.phone)
      formData.append("email", data?.email)
      formData.append("password", data?.password)
      formData.append("password_confirmation", data?.confirmPassword)
      formData.append("user_type", state?.userType)
      formData.append("account_number", data?.accountNumber)
      formData.append("account_name", data?.accountName)
      formData.append("bank_name", data?.bankName)
      formData.append("iban", data?.iban)

      await AuthServices.checkEmailPhone(data.email, data.phone)

      const { message } = await AuthServices.register(formData)
      SuccessToaster(message)
      navigate('/')
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, []);

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="become-saller-wrapper w-full">
        <div className="title mb-10">
          <PageTitle
            title={`Registration Application for ${userName}`}
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Guest Register", path: "/guest-register" },
            ]}
          />
        </div>
        <div className="content-wrapper w-full mb-10">
          <div className="container-x mx-auto">
            <div className="w-full bg-white sm:p-[30px] p-3">
              <div className="flex xl:flex-row flex-col-reverse xl:space-x-11" style={{ justifyContent: 'space-evenly' }}>
                <form className="xl:w-[824px]" onSubmit={handleSubmit(registration)}>
                  <div className="title w-full mb-4">
                    <h1 className="text-[22px] font-semibold text-qblack mb-1">
                      Registration Information
                    </h1>
                    <p className="text-[15px] text-qgraytwo">
                      Fill the form below or write us .We will help you as soon
                      as possible.
                    </p>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Name"
                        label="Name*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.name?.message}
                        register={register("name", {
                          required: 'Please enter a name.',
                        })}
                      />

                      <InputCom
                        placeholder="username"
                        label="Username"
                        type="text"
                        inputClasses="h-[50px]"
                        register={register("username")}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="example@email.com"
                        label="Email Address*"
                        type="email"
                        inputClasses="h-[50px]"
                        errors={errors?.email?.message}
                        register={register("email", {
                          required: 'Please enter an email address.',
                          pattern: {
                            value: emailRegex,
                            message: 'Please enter a valid email.',
                          }
                        })}
                      />

                      <InputCom
                        placeholder="+92312 *********"
                        label="Phone*"
                        type="tel"
                        inputClasses="h-[50px]"
                        errors={errors?.phone?.message}
                        register={register("phone", {
                          required: 'Please enter a phone number.',
                          pattern: {
                            value: mobileRegex,
                            message: 'Please enter a mobile number.',
                          }
                        })}
                      />
                    </div>
                  </div>

                  {/* ============================================================== */}
                  <div className="title w-full mb-4">
                    <h1 className="text-[22px] font-semibold text-qblack mb-1">
                      Account Information
                    </h1>
                    <p className="text-[15px] text-qgraytwo">
                      Fill the form below or write us .We will help you as soon
                      as possible.
                    </p>
                  </div>
                  <div className="input-area">
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Bank Name"
                        label="Bank Name*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.bankName?.message}
                        register={register("bankName", {
                          required: 'Please enter a bank name.',
                        })}
                      />

                      <InputCom
                        placeholder="Account Title"
                        label="Account Title*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.accountName?.message}
                        register={register("accountName", {
                          required: 'Please enter a account title.',
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-5">
                      <InputCom
                        placeholder="Account Number"
                        label="Account Number*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.accountNumber?.message}
                        register={register("accountNumber", {
                          required: 'Please enter a account number.',
                        })}
                      />

                      <InputCom
                        placeholder="IBAN Number"
                        label="IBAN Number*"
                        type="text"
                        inputClasses="h-[50px]"
                        errors={errors?.iban?.message}
                        register={register("iban", {
                          required: 'Please enter a iban number.',
                        })}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col space-y-5 sm:space-y-0 sm:space-x-5 mb-[30px]">
                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Password*"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        inputClasses="h-[50px]"
                        children={
                          <Box sx={{ width: '50px' }}>
                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                            </IconButton>
                          </Box>
                        }
                        errors={errors?.password?.message}
                        register={register("password", {
                          required: 'Please enter password.',
                          pattern: {
                            value: passwordRegex,
                            message: 'Password contain minimum 8 characters, at least uppercase/lowercase letter, number and special character',
                          }
                        })}
                      />

                      <InputCom
                        placeholder="● ● ● ● ● ●"
                        label="Re-enter Password*"
                        name="repassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        inputClasses="h-[50px]"
                        children={
                          <Box sx={{ width: '50px' }}>
                            <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                            </IconButton>
                          </Box>
                        }
                        errors={errors?.confirmPassword?.message}
                        register={register("confirmPassword", {
                          required: 'Please enter confirm password.',
                          validate: value => value === password.current || "Confirm password does not match."
                        })}
                      />
                    </div>
                    <div className="signin-area mb-3">
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          disabled={loading}
                          className="black-btn text-sm text-white w-[490px] h-[50px] font-semibold flex justify-center bg-purple items-center"
                        >
                          <span>Create Account</span>
                          {loading && (
                            <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                              <LoaderStyleOne />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="signup-area flex justify-center">
                      <p className="text-sm text-qgraytwo font-normal">
                        Alrady have an Account?
                        <a href="/login" className="ml-2 text-qblack">
                          Log In
                        </a>
                      </p>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
