import { get, post } from '..';
import CartRoutes from './Cart.Routes';

const CartServices = {
  getCartData: async (params) => {
    const data = await get(CartRoutes.getCartData, params);
    return data;
  },
  setCartData: async (obj) => {
    const data = await post(CartRoutes.setCartData, obj);
    return data;
  },
  deleteCartData: async (params) => {
    const data = await post(CartRoutes.deleteCartData, params);
    return data;
  },
}

export default CartServices